import $ from 'jquery';

//输入框仅可输入数字
function setInputDataType() {
    //data-type=number
    const dataTypeNumberList = $('input[data-type=number]');
    if(dataTypeNumberList.length > 0) {
        dataTypeNumberList.map(function () {
            //输入检测
            $(this).keyup(function () {
                //检查输入是否为数字
                if(isNaN($(this).val().trim())) {
                    //输入不为数字
                    $(this).val($(this).val().slice(0, -1));
                }
            });
        });
    }
}
setInputDataType();

export default setInputDataType;
import { createRouter,createWebHistory } from 'vue-router';
import LoginApp from '@/components/login/LoginApp.vue';

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login/:memberPid',
            component: LoginApp
        }
    ]
});
import $ from 'jquery';

//手机号输入框仅可数字“1”开头
function setInputBeginWithOne() {
    const cellphoneInputList = $("input[name=cellphone]");
    if(cellphoneInputList.length > 0) {
        cellphoneInputList.map(function () {
            $(this).keyup(function () {
                //首字符必须为1
                if($(this).val().charAt(0) !== "1") {
                    $(this).val("");
                }
            });
        });
    }
    
}

export default setInputBeginWithOne;
import $ from 'jquery';

//输入框仅可输入数字
function setInputErrorTips() {
    //data-type=number
    const inputList = $('input[type=text],input[type=password]');
    if(inputList.length > 0) {
        inputList.map(function () {
            //如果有错误提示的span则提示，否则不执行操作
            if($(this).parents(".my-input-wrap").find(".my-error-tips").length > 0) {
                $(this).focus(function () {
                    if($(this).val() === "" || $(this).val() === undefined) {
                        //输入内容为空
                        $(this).parents('.my-input-wrap')
                                .addClass("show-border")
                                .find(".my-error-tips")
                                .addClass("show");
                    }
                });
            }
        });
    }
}

export default setInputErrorTips;
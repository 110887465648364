<template>
    <img src="@/assets/svg/logo.svg" alt="logo">
</template>

<script>
    export default {
        name: 'Logo'
    }
</script>

<style scoped>
    img {
        display: block;
        margin: 0 auto;
        max-width: 500px;
        width: 31.25rem;
        max-height: 92px;
        height: 5.75rem;
        position: absolute;
        top: 6.25rem;
        left: 7.8125rem;
    }
</style>
  
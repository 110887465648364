<template>
    <img src="@/assets/image/bg-wave.png" alt="">
</template>

<script>
    export default {
      name: 'BackgroundWave',
    }
</script>

<style scoped>
    img {
        display: block;
        max-width: 750px;
        width: 46.875rem;
        max-height: 472px;
        height: 29.5rem;
        padding-top: 2.0625rem;
        z-index: 1;
    }
</style>

//控制页面自动缩放
function autoControlScale() {
    //获取当前的网页宽度
    let windowWidth = window.innerWidth >= 750 ? 750 : window.innerWidth;
    if(windowWidth < 750) {
        //基于当前宽度与750的比例设定html字体大小
        let htmlFontSize = windowWidth / 750 * 16;

        //设置css
        const htmlNode = document.querySelector('html');
        htmlNode.style.setProperty('font-size',htmlFontSize + 'px');
    }

    document.documentElement.style.setProperty('--window-width', window.innerWidth + 'px');
}
autoControlScale();

//页面发生变化时自动重新计算
window.addEventListener('resize', () => {
    autoControlScale();
});

export default autoControlScale;
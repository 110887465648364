<template>
    <span>©版权所有：中食产业科技（广州）有限公司</span>
    <a href="https://www.miit.gov.cn/">{{ icp }}</a>
</template>

<script>
    import axios from 'axios';
    import { ref } from 'vue';

    export default {
        name: 'Footer',
        setup() {
            const icp = ref('');
            
            //ajax获取icp备案信息
            axios.post('/ajax/common/getIcp').then(
                response => {
                    icp.value = response.data;
                }
            );

            return {
                icp
            }
        }
    }
</script>

<style scoped>
    span,a {
        display: block;
        font-size: 1.5rem;
        color: rgba(255, 255, 255, 0.498039215686275);
        text-align: center;
    }

    a {
        margin-top: 1.0625rem;
        padding-bottom: 3rem;
    }
</style>
  
<template>
    <img src="@/assets/svg/publicity3.svg" alt="publicity3">
</template>

<script>
    export default {
        name: 'Publicity2'
    }
</script>

<style scoped>
    img {
        display: block;
        margin: 0 auto;
        max-width: 523px;
        width: 32.6875rem;
        max-height: 110px;
        height: 6.875rem;
        position: absolute;
        top: 35.1875rem;
        left: 7.09375rem;
    }
</style>
  
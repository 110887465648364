<template>
    <div class="login-form">
        <h2>手机登录/注册</h2>
        <form action="/login" method="post" class="pt-78">
            <div class="my-input-wrap">
                <input type="text" class="form-control" name="cellphone" data-type="number" data-length="11" autocomplete="off" value="" placeholder="输入手机号" />
                <span class="my-error-tips hide">请输入手机号码（1开头11位数字）</span>
            </div>
            <div class="my-input-wrap">
                <div class="input-group">
                    <input type="text" class="form-control" name="verifyCode" data-type="number" data-length="6" autocomplete="off" value="" placeholder="输入验证码" />
                    <a href="javascript:void(0);" @click="sendMessage" class="input-group-btn">获取验证码</a>
                </div>
                <span class="my-error-tips hide">请输入验证码</span>
            </div>
            <input type="hidden" name="memberPid" v-if="memberPid !== undefined && memberPid !== ''" v-model="memberPid" />
            <button @click="checkForm($event)" type="submit" class="mt-30">登录/注册</button>
        </form>
    </div>
</template>

<script>
    import $ from 'jquery';
    import axios from 'axios';
    import { computed, onMounted } from 'vue';
    import setInputDataType from '@/hooks/setInputDataType';
    import setInputBeginWithOne from '@/hooks/setInputBeginWithOne';
    import setInputLength from '@/hooks/setInputLength';
    import setInputErrorTips from '@/hooks/setInputErrorTips';

    //引入路由
    import { useRoute } from 'vue-router';
    
    export default {
        name: 'LoginForm',
        setup() {
            //获取路径参数
            const route = useRoute();
            const memberPid = computed(() => route.params.memberPid);

            //点击“获取验证码”，发送短信
            //初始化倒计时定时器
            let timer = null;
            function sendMessage() {
                //已有在计时的定时器，则不做任何操作做
                if(timer !== null) return;

                //获取手机手机号
                const formData = new Object();
                formData.cellphone = $('input[name=cellphone]').val();
                formData.memberPid = memberPid.value;
                if(formData.cellphone !== undefined && formData.cellphone !== '') {
                    //发送请求
                    axios.defaults.headers['content-type'] = 'application/x-www-form-urlencoded';
                    axios.post('/ajax/login/sms', formData).then(
                        response => {
                            //短信发送成功，返回，无返回
                            //console.log(response.data);
                        }
                    );

                    //开始1分钟倒计时
                    let seconds = 60;
                    timer = setInterval( function () {
                        $('.input-group-btn').text('( ' + seconds + ' )');
                        seconds--;
                        if(seconds === 0) {
                            //倒计时结束，清除计时器
                            clearInterval(timer);

                            //设置为获取短信码
                            $('.input-group-btn').text("获取短信码");
                        }
                    }, 1000);
                }
            }

            //设置提交按钮事件
            function checkForm(event) {
                const inputList = $('input[type=text],input[type=password]');
                if(inputList.length > 0) {
                    //获得焦点显示输入提示
                    inputList.map(function () {
                        //如果有错误提示的span则提示，否则不执行操作
                        if($(this).val() === '' || $(this).val === undefined) {
                            //阻止提交
                            event.preventDefault();
                            
                            //如果为组合输入框时，把组合的按钮边框高亮
                            if($(this).parents('.input-group').length > 0) {
                                $(this).parents('.my-input-wrap')
                                       .addClass('show-border')
                                       .find('.my-error-tips')
                                       .addClass("show");
                            } else {
                                $(this).parents('.my-input-wrap')
                                       .addClass('show-border')
                                       .find('.my-error-tips')
                                       .addClass("show");
                            }
                        }
                    });
                }
            }

            onMounted(() => {
                //设置input
                setInputDataType();
                setInputBeginWithOne();
                setInputLength();
                setInputErrorTips();
            });

            return {
                sendMessage,checkForm,memberPid
            }
        }
    }
</script>

<style scoped>
    .login-form {
        max-width: 670px;
        width: 41.875rem;
        max-height: 714px;
        height: 44.625rem;
        background-color: #FFF;
        border-radius: 1.875rem;
        margin: 0 auto;
        margin-top: 19.25rem;
    }
    h2 {
        font-size: 2rem;
        font-weight: 700;
        max-height: 137px;
        height: 8.5625rem;
        line-height: 8.5625rem;
        max-width: 607px;
        width: 37.9375rem;
        margin: 0 auto;
        text-align: center;
        background-image: url('@/assets/svg/login-form-dashed.svg');
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-size: 38.1875rem 0.125rem;
    }
    form {
        max-height: 577px;
        height: 36.0625rem;
        max-width: 607px;
        width: 37.9375rem;
        margin: 0 auto;
    }
    input {
        max-height: 96px;
        height: 6rem;
        border-radius: 3rem;
        font-size: 1.75rem;
        background-color: rgba(243, 245, 249, 1);
        color: rgb(153, 153, 153);
        padding-left: 2.1875rem;
        border: 1px rgba(243, 245, 249, 1) solid;
    }
    .form-control:focus {
        border: 1px rgba(243, 245, 249, 1) solid;
    }
    input:focus {
        background-color: rgba(243, 245, 249, 1);
    }
    input::placeholder {
        color: rgb(153, 153, 153);
    }
    .my-error-tips {
        display: inline-block;
        padding-left: 2.1875rem;
        font-size: 1.5rem;
        color: #FF6D64;
        max-height: 50px;
        height: 3.125rem;
    }
    .input-group input {
        border-right: none;
    }
    .input-group-btn {
        display: block;
        max-height: 96px;
        height: 6rem;
        line-height: 6rem;
        border-top-right-radius: 3rem;
        border-bottom-right-radius: 3rem;
        background-color: rgba(243, 245, 249, 1);
        border-left: none;
        padding: 0 2.1875rem;
        color: #1890FF;
        font-size: 1.75rem;
        max-width: 234px;
        width: 14.625rem;
        text-align: center;
    }
    button {
        max-height: 96px;
        height: 6rem;
        border-radius: 3rem;
        background-color: rgba(24, 144, 255, 1);
        width: 100%;
        border: none;
        font-size: 1.75rem;
        color: #FFF;
    }
    /*边框高亮提示*/
    .my-input-wrap.show-border input {
        border: 1px rgba(24, 144, 255, 1) solid;
    }
    .my-input-wrap.show-border .input-group input {
        border-right: none;
    }
    .my-input-wrap.show-border .input-group .input-group-btn {
        border: 1px rgba(24, 144, 255, 1) solid;
        border-left: none;
    }
</style>
  
<template>
    <a href="javascript:void(0);" class="go-top"></a>
</template>

<script>
    import $ from 'jquery';
    import { onMounted } from 'vue';
    export default {
        name: 'GoTop',
        setup() {
            onMounted(() => {
                const goTopBtn = $(".go-top");
                if(goTopBtn.length > 0) {
                    goTopBtn.map(function () {
                        $(this).click(function () {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            });
                        });
                    });
                }

                function scrollIn() {
                    $('.go-top').fadeIn();
                }
                function scrollOut() {
                    $('.go-top').fadeOut();
                }
                $(window).scroll(function() {
                    if($(this).scrollTop() > 40) {
                        scrollIn();
                    } else {
                        scrollOut();
                    }
                });
            })
        }
    }
</script>

<style scoped>
    .go-top {
        position: fixed;
        right: 2.5rem;
        bottom: 6.25rem;
        display: none;
        max-height: 80px;
        height: 5rem;
        max-width: 80px;
        width: 5rem;
        background-image: url("@/assets/svg/go-top.svg");
        background-size: 5rem 5rem;
        background-repeat: no-repeat;
    }
</style>

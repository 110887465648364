import $ from 'jquery';

//手机号输入框仅可数字“1”开头
function setInputLength() {
    const limitLengthInputList = $('input[data-length]');
    if(limitLengthInputList.length > 0) {
        limitLengthInputList.map(function () {
            //获取限制的长度
            let limitLength = parseInt($(this).attr("data-length"));

            $(this).keyup(function () {
                //获取当前的value值
                let nowValue = $(this).val();

                if(nowValue.length > limitLength) {
                    //输入超过指定长度，禁止继续输入，去掉输入的最后一个字符
                    $(this).val(nowValue.slice(0, -1));

                } else if(nowValue.length === limitLength) {
                    //输入长度符合要求
                    //去掉错误提示和边框高亮
                    $(this).parents(".my-input-wrap")
                           .removeClass("show-border")
                           .find(".my-error-tips")
                           .removeClass("show");
                } else if(nowValue.length < limitLength) {
                    $(this).parents(".my-input-wrap")
                           .addClass("show-border")
                           .find(".my-error-tips")
                           .addClass("show");
                }
            });
        });
    }
}

export default setInputLength;
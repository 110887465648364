<template>
    <img src="@/assets/svg/publicity2.svg" alt="publicity2">
</template>

<script>
    export default {
        name: 'Publicity2'
    }
</script>

<style scoped>
    img {
        display: block;
        margin: 0 auto;
        max-width: 392px;
        width: 24.5rem;
        max-height: 33px;
        height: 2.0625rem;
        position: absolute;
        top: 28.1875rem;
        left: 11.1875rem;
    }
</style>
  
<template>
    <img src="@/assets/svg/zs-logo.svg" alt="logo">
</template>

<script>
    export default {
        name: 'ZsLogo'
    }
</script>

<style scoped>
    img {
        display: block;
        margin: 5rem auto 5.3125rem auto;
        max-width: 143px;
        width: 8.9375rem;
        max-height: 92px;
        height: 5.75rem;
    }
</style>
  
<template>
    <div class="bg-diamond"></div>
</template>

<script>
    import $ from 'jquery';
    export default {
        name: 'BackgroundDiamond',
        setup() {
            function bgDiamondToggle() {
                const bgDiamond = $('.bg-diamond');
                if(bgDiamond.is(':visible')) {
                    //显示，则隐藏
                    bgDiamond.fadeOut(1000);
                } else {
                    //隐藏，则显示
                    bgDiamond.fadeIn(900);
                }
            }
            //设置定时器
            setInterval(function () {
              bgDiamondToggle();
            }, 1000);
        }
    }
</script>

<style scoped>
    div.bg-diamond {
        background-image: url('@/assets/image/bg-diamond.png');
        background-repeat: no-repeat;
        background-size: 58.1875rem 9rem;
        background-position-x: -5.65625rem;
        overflow: hidden;
        display: block;
        max-width: 750px;
        width: 46.875rem;
        max-height: 144px;
        height: 9rem;
        position: absolute;
        top: 24.25rem;
        z-index: 1;
    }
</style>

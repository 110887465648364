<template>
    <img src="@/assets/svg/publicity1.svg" alt="publicity1">
</template>

<script>
    export default {
        name: 'Publicity1'
    }
</script>

<style scoped>
    img {
        display: block;
        margin: 0 auto;
        max-width: 677px;
        width: 42.3125rem;
        max-height: 99px;
        height: 6.1875rem;
        position: absolute;
        top: 16.875rem;
        left: 2.28125rem;
    }
</style>
  
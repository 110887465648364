<template>
    <!--规则模态窗口-->
    <div id="end-notification-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <button v-show="isCanCloseEndNotification" type="button" class="btn-close" aria-label="Close" data-bs-toggle="modal" data-bs-target="#end-notification-modal"></button>
                <div class="modal-body-wapper">
                    <div class="modal-body" v-html="endNotification">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { Modal } from 'bootstrap';
    import { useStore } from 'vuex';
    import axios from 'axios';

    //获取store
    const store = useStore();

    //初始化播种计划规则模态窗口变量
    let endNotificationModal = null;
    onMounted(() => {
        endNotificationModal = new Modal('#end-notification-modal');
    });

    //ajax获取是否显示停运通知
    const isShowEndNotification = ref(0);//默认为0，不显示
    axios.post('/ajax/login/isShowEndNotification').then(
        response => {
            if(response.data == 1 || response.data == "1") {
                //显示停运通知
                isShowEndNotification.value = 1;
            }
        }
    );

    //监视isShowEndNotification
    watch(() => isShowEndNotification.value, (newVal,oldVal) => {
        if(newVal === 1) {
            endNotificationModal.show();
        } else {
            endNotificationModal.hide();
        }
    });

    //ajax获取是否可以关闭通知
    const isCanCloseEndNotification = ref(true);//默认为true，可关闭
    axios.post('/ajax/login/isCanCloseEndNotification').then(
        response => {
            isCanCloseEndNotification.value = response.data;
        }
    );

    //ajax获取关闭通知内容化
    const endNotification = ref("");//默认为空
    axios.post('/ajax/login/getEndNotification').then(
        response => {
            endNotification.value = response.data;
        }
    );
</script>

<style scoped>
    /* 规则 */
    .modal-dialog, 
    .modal-content,
    .modal-body,
    .modal-body-wapper {
        max-width: 560px;
        width: 35rem;
        max-height: 1074px;
        border-radius: 1.875rem;
    }
    .modal-body-wapper {
        overflow: hidden;
    }
    .modal-body {
        padding: 1.875rem;
        text-align: justify;
    }
    .modal-content {
        overflow: visible !important;
    }
    .btn-close {
        visibility: visible;
        max-width: 38px;
        width: 2.375rem;
        max-height: 38px;
        height: 2.375rem;
        padding: 0;
        background-image: url('@/assets/svg/close-modal.svg');
        background-size: 3.5rem;
        position: absolute;
        top: -2.25rem;
        right: -2.25rem;
        opacity: 1;
    } 

    @media(max-width: 576px) {
        .modal-dialog {
            margin: 0 auto !important;
        }
    }

    /* 规则内容解析 */
    :deep() {
        .modal-body h1,
        .modal-body p {
            font-size: 1.75rem;
            line-height: 3.75rem;
        }
        .modal-body h1 {
            text-align: center;
            font-weight: bold;
        }
    }
</style>
  